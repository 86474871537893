import Base from './Base';
import { YouTubeEmbedAttrs } from './YouTubeEmbed';

export interface SettingPageGGShiver {
  title: string;
  content: string;
  videoTitle: string;
  youtube: string;
  youtubeEmbed: YouTubeEmbedAttrs;
}

export interface SettingAttrs {
  id?:
    | 'banners.unbranded'
    | 'banners.ggShiver'
    | 'pages.faq'
    | 'pages.ggShiver'
    | 'pages.privacyPolicy'
    | 'pages.tos';
  type?: string;
  value?: any;
  createdAt?: string;
  updatedAt?: string;
}

export default class Setting extends Base<SettingAttrs> {
  static async fetchByID(id: SettingAttrs['id']) {
    const response = await this.$api.get<{ data: SettingAttrs }>(
      `v1/settings/${id}`
    );
    return response.data.data;
  }
}
